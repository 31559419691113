<template>
  <div>
    <v-simple-table class="table-padding-2-no-top">
      <template v-slot:default>
        <tbody v-for="key in groupKeys" :key="key">
          <tr class="font-weight-medium text-center">
            <td>Danh mục</td>
            <td>Tên</td>
            <td>Đặc tả</td>
            <td v-for="(val, vi) in groupItems[key].items" :key="vi">
              {{ val.size }} -
              <span
                @click="downloadRecordFile(val)"
                class="cursor-pointer primary--text text-decoration-underline"
              >
                File
              </span>
            </td>
            <td>Tổng</td>
            <td style="width: 75px">
              <v-btn
                v-if="item.production_status === 1"
                color="success"
                small
                @click="confirmMaterial"
                :disabled="isDisabledBtnOk"
              >
                {{ $t("labels.ok") }}
              </v-btn>
            </td>
          </tr>
          <tr class="text-center">
            <td>{{ groupItems[key].category_name }}</td>
            <td>{{ groupItems[key].name }}</td>
            <td>{{ groupItems[key].description }}</td>
            <td
              v-for="(val, vi) in groupItems[key].items"
              :key="vi"
              class="font-weight-medium error--text"
            >
              {{ formatNumber(val.quantity) }}
            </td>
            <td class="font-weight-medium error--text">
              {{ formatNumber(groupItems[key].total) }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table class="table-padding-2-no-top table-h-36 mt-8">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" style="width: 100px">Phương án</th>
            <th class="text-center">Chi tiết</th>
            <th class="text-center" style="width: 150px">SKU nguyên liệu</th>
            <th class="text-center">Tên</th>
            <th class="text-center">Đặc tả</th>
            <th class="text-center">Size</th>
            <th class="text-center">Đơn vị</th>
            <th
              class="text-center"
              style="width: 75px"
              v-if="item.production_status < 4"
            >
              <v-btn x-small color="primary" @click="addConfig">
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody v-for="(plan, index) in plans" :key="`p_${index}_${plan.key}`">
          <tr
            v-for="(pItem, pKey) in plan.items"
            :key="`i_${plan.key}_${pKey}`"
            class="text-center"
          >
            <td v-if="pKey === 0" :rowspan="plan.items.length">
              {{ plan.name }}
            </td>
            <td class="error--text font-weight-medium">
              {{ pItem.semi_finished_name }}
            </td>
            <td>
              <v-text-field
                v-if="plan.editing"
                class="c-input-small"
                v-model="pItem.code"
                outlined
                dense
                hide-details
                single-line
                @keyup.enter="getItemDetail(pItem, pKey, index)"
              ></v-text-field>
              <span v-else>{{ pItem.sku }}</span>
            </td>
            <td>{{ pItem.name }}</td>
            <td>{{ pItem.description }}</td>
            <td>{{ pItem.size }}</td>
            <td>{{ pItem.unit }}</td>
            <td
              v-if="pKey === 0 && item.production_status < 4"
              :rowspan="plan.items.length"
            >
              <template v-if="plan.editing">
                <v-btn
                  x-small
                  color="success"
                  :disabled="checkPlan(plan)"
                  @click="save(plan)"
                  class="mb-1"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(plan, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  v-if="item.production_status < 4"
                  x-small
                  color="error"
                  @click="deactiveItem(plan)"
                >
                  {{ $t("labels.delete") }}
                </v-btn>
              </template>
            </td>
          </tr>
          <tr>
            <td colspan="8"></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { MATERIAL_CLASSIFY_MATERIAL } from "@/libs/const";

export default {
  name: "RequirementQuantitativePlan",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    semiFinishedItems: [],
    plans: [],
  }),
  computed: {
    isDisabledBtnOk() {
      const checkPlan = [...this.plans].filter((pl) => !pl.id);
      return checkPlan.length > 0;
    },
    groupItems() {
      const group = {};
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        const key = `${item.category_name}_${item.name}_${item.description}`;
        if (!group[key]) {
          group[key] = {
            total: 0,
            category_name: item.category_name,
            name: item.name,
            description: item.description,
            items: [],
          };
        }
        group[key].total += item.quantity;
        group[key].items.push({
          size: item.size,
          quantity: item.quantity,
          id_goods_production_record: item.id_goods_production_record,
          goods_production_record_tracking_id:
            item.goods_production_record_tracking_id,
          id_goods_production_requirement: item.id_goods_production_requirement,
          id_material: item.id_material,
        });
      }
      return group;
    },
    groupKeys() {
      return Object.keys(this.groupItems);
    },
  },
  created() {},
  mounted() {
    this.getPlanDetail();
    this.getGroupSemiFinished();
  },
  methods: {
    async confirmMaterial() {
      if (
        !confirm(
          this.$t(`messages.goods_production_requirement_confirm`, {
            tracking: this.item.tracking_id,
          })
        )
      ) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post(
          "/goods-production-requirement-confirm-material",
          {
            id_goods_production_requirement: this.item.id,
          }
        );
        this.isLoading = false;
        const msg = this.$t("messages.saved");
        this.$vToastify.success(msg);
        this.$emit("confirmMaterial", true);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    checkPlan(plan) {
      const checkItem = [...plan.items].filter(
        (item) => !item.id_material || !item.sku
      );
      return checkItem.length > 0;
    },
    async downloadRecordFile(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = `${item.goods_production_record_tracking_id}.zip`;

      try {
        await this.downloadExcelFile(
          "/download-goods-production-record-file",
          {
            id_goods_production_record: item.id_goods_production_record,
          },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    getPlanDetail: debounce(function () {
      httpClient
        .post("/goods-production-requirement-plan-detail", {
          id_goods_production_requirement: this.item.id,
        })
        .then(({ data }) => {
          this.plans = [...data];
          if (this.plans.length === 0) {
            this.addConfig();
          }
        });
    }, 500),

    getGroupSemiFinished: debounce(function () {
      httpClient
        .post("/goods-production-requirement-get-group-semi-finished", {
          id_goods_production_requirement: this.item.id,
        })
        .then(({ data }) => {
          this.semiFinishedItems = [...data];
        });
    }, 500),

    async save(plan) {
      if (this.checkPlan(plan)) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-requirement-plan-save", {
          id_goods_production_requirement: this.item.id,
          id: plan.id,
          name: plan.name,
          items: plan.items,
        });
        this.isLoading = false;
        const msg = this.$t("messages.saved");
        this.$vToastify.success(msg);
        this.getPlanDetail();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async getItemDetail(pItem, pKey, index) {
      if (!pItem.code) {
        return false;
      }

      try {
        const { data } = await httpClient.post(
          "/material-get-by-code-and-classify",
          {
            code: pItem.code,
            classify: MATERIAL_CLASSIFY_MATERIAL,
          }
        );
        const plans = [...this.plans];
        plans[index].items[pKey] = { ...pItem, ...data };
        this.plans = [...plans];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async deactiveItem(item) {
      if (!confirm(this.$t("messages.are_you_sure_delete"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-requirement-deactive-detail", {
          id_goods_production_requirement: this.item.id,
          id: item.id,
          type: "plans",
        });
        this.isLoading = false;
        const msg = this.$t("messages.saved");
        this.$vToastify.success(msg);
        this.getPlanDetail();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.plans = [...this.plans].filter((i, idx) => idx !== index);
      } else {
        this.plans[index] = { ...item };
      }
      this.plans = [...this.plans];
    },

    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      const items = [...this.semiFinishedItems].map((item) => ({
        id_semi_finished: item.id,
        semi_finished_name: item.name,
        id_material: null,
        code: null,
        sku: null,
        name: null,
        size: null,
        unit: null,
      }));
      this.plans.push({
        id: null,
        name: `Phương án ${this.plans.length + 1}`,
        editing: true,
        items,
      });
    },
  },
};
</script>

<style scoped></style>
